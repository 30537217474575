import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  fill: "#1d1d1b",
  viewBox: "0 0 500 500"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      d: "M407.8 117.4c-.6 1.3-1.3 2.5-1.7 3.8-1 3.2-3 5.9-5.5 8-3.9 3.4-8 6.6-12.1 9.8-2.3 1.8-4.6 3.6-7.1 5.2-1.4.9-1.5 2.1-1.4 3.4.2 2.1.6 4.1.6 6.2 0 23.2.1 46.4 0 69.6 0 8.1-1.3 16.2-6 23.1-2.3 3.5-5.2 6.6-8.1 9.6-20.3 20.4-40.7 40.7-61.1 61.1-19.1 19.1-38.2 38.3-57.4 57.4-8.8 8.8-17.6 17.7-26.5 26.4-2.2 2.1-4.9 3.7-7.5 5.3-.8.5-2 .7-2.9 1-1.7 0-3.5 0-5.2.1-.3 0-.7 0-1-.1q-5.25-2.1-9.3-6L125.3 331l-26.6-26.6c-3.2-3.2-5.9-6.7-6.5-11.3-.5-3.3.2-6.3 1.9-9.1 2.2-3.8 5.4-6.7 8.5-9.7l131.1-131.1c5.3-5.3 10.6-10.6 16.2-15.5 4.7-4.2 10.4-6.8 16.7-7.6 4.4-.6 8.9-.9 13.4-.9 22-.1 44.1-.1 66.1 0 8.7 0 16.7 2.2 23 8.4 2.4 2.4 4.5 5.1 6.9 7.9 1.5-1.1 3.3-2.4 5-3.7 4.1-3.2 8.5-6.1 12.2-9.6 5.8-5.4 5.2-14.3-2-18.3-4.7-2.6-9.3-1.8-13.5 2.2-2.1 2-3.9 4.2-5.9 6.3-2.1 2.3-4.3 2.3-6.9.3-2.4-1.9-2.5-4.6-.8-6.6 3.1-3.5 6.3-7.1 10-10 5.6-4.5 12.1-4.8 18.9-2.7 5.6 1.7 9.5 5.5 12.1 10.6.7 1.5.9 3.2 1.5 4.8.1.4.6.8.9 1.1v.3c-.2.1-.5.2-.5.3.1 1.4.3 2.9.5 4.3.3.9.3 1.7.3 2.6zM273 129.9c-3.8-.2-7.3.8-10.8 2.1-4.4 1.6-7.6 4.9-10.8 8.1-10.1 10-20.2 20.1-30.2 30.1-37.5 37.5-75.1 75-112.5 112.6-2 2-3.8 4.3-5.6 6.5-.9 1.1-1.2 2.5-.3 3.8.9 1.4 1.8 2.9 2.9 4.1 32.4 32.5 64.9 65 97.4 97.4.8.8 1.8 1.5 2.8 2.1 1.1.7 2.5 1.3 3.7.5 1.9-1.2 3.9-2.4 5.4-3.9 22.6-22.5 45.1-45.1 67.7-67.6 26.4-26.3 52.7-52.7 79.1-78.9 5.7-5.7 8.4-12.5 8.4-20.3.1-22.9 0-45.8 0-68.7v-5.9c-.7.3-1.2.5-1.7.8l-13.8 8.7c-2.4 1.5-2.6 1.8-2.1 4.5.8 4.6-.4 8.9-2.5 12.9-3.8 7.2-10.3 10.5-18.3 10.9-3.2.2-6.3-.7-9.2-2.1-7.4-3.6-11.4-9.7-12.2-17.8-.4-3.4.3-6.8 1.8-9.9 4.8-10.4 15-14.7 25.6-11.9 3.6.9 6.5 2.9 9.2 5.3.4.3 1.3.6 1.6.4 3-1.8 5.9-3.7 8.9-5.5 3-1.9 6-3.9 9-5.9.7-.5 1.1-1 .5-1.9-2-3.2-4.8-5.6-8.1-7.4-2.1-1.1-4.4-2-6.7-2.4-3.5-.5-7.1-.5-10.7-.5h-68.1c-.1-.2-.2-.2-.4-.2zm69.9 38.1c-.9.5-1.7.9-2.5 1.2-2.2 1.1-4.4 2.4-6.8 3.2-1.2.4-3 .4-4-.2-3.5-1.9-3.8-6.2-.2-8.4 2.2-1.3 4.6-2.5 6.9-3.8.5-.3 1-.6 1.6-.9-3.2-3.3-10-2.6-12.8 0-4.5 4.1-5.7 8.8-3.7 12.9 2.1 4.5 7.5 8.6 13.5 6.5 4.7-1.3 8.4-6 8-10.5z"
    }, null, -1),
    _createElementVNode("path", {
      stroke: "currentColor",
      d: "M407.8 114.9c-.2-1.4-.3-2.9-.5-4.3 0-.1.3-.2.5-.3zM206 407.5c1.7 0 3.5 0 5.2-.1.1.1.2.2.3.4h-5.6c0-.1 0-.2.1-.3z"
    }, null, -1),
    _createElementVNode("path", {
      stroke: "currentColor",
      d: "M206 407.5c0 .1-.1.2-.1.3h-.9v-.3c.3-.1.6 0 1 0zm67.5-277.6h-.4zM237 255.2v-53.5c0-3.2 2.5-6.1 5.7-5.8 1.9.2 4 1.6 4.4 3.5.2 1.1.4 2.2.4 3.3v105.1c0 3.7-1.2 5.8-4.6 6.6-1.8.4-3.3-.4-4.3-1.7-.9-1.2-1.6-2.9-1.6-4.3zm46.4-23.2c11.8-.3 20.1 9.4 20.5 19.1.6 14.3-10.1 22.2-20.9 22.1s-19.9-9.4-20.1-20.8c-.1-10.5 10-21.1 20.5-20.4zM273 252.7c0 5.6 5.2 11 10.6 10.8 5.5-.2 10.5-4.8 10.4-10.9-.1-5.6-3.9-10.7-10.8-10.6-5.6-.1-10.2 4.8-10.2 10.7zM205.8 232c11.7-.2 19.9 9.1 20.4 18.9.6 14.1-9.4 22.7-21.6 22.3-9.3-.3-19.4-8.7-19.4-21.1.1-10.2 10-20.8 20.6-20.1zm-10.4 20.7c.1 5.6 5.2 10.9 10.6 10.8 5.3-.1 10.7-5.1 10.4-11.8-.2-4.2-3.8-9.9-10.5-9.8-6.4 0-10.8 5.7-10.5 10.8z"
    }, null, -1)
  ])))
}
export default { render: render }